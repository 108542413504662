import { ApolloError } from '@apollo/client';

import Error from '../components/error';

export enum ErrorCode {
  NOT_FOUND = 'NOT_FOUND',
  PERMISSIONS = 'PERMISSIONS',
  SERVER_ERROR = 'SERVER_ERROR',
  VALIDATION = 'VALIDATION',
}

interface ErrorCheck {
  (error: ApolloError): boolean;
}

export const isNotFoundError: ErrorCheck = error =>
  Boolean(
    error.graphQLErrors.length > 0 &&
      error.graphQLErrors.find(x => x.extensions?.code === ErrorCode.NOT_FOUND)
  );

export const isPermissionsError: ErrorCheck = error =>
  Boolean(
    error.graphQLErrors.length > 0 &&
      error.graphQLErrors.find(x => x.extensions?.code === ErrorCode.PERMISSIONS)
  );

export const ServerError: ErrorCheck = error =>
  Boolean(
    error.graphQLErrors.length > 0 &&
      error.graphQLErrors.find(x => x.extensions?.code === ErrorCode.SERVER_ERROR)
  );

const handleError = (error: ApolloError) => {
  if (process.env.NODE_ENV !== 'test') {
    console.error(error);
  }

  if (isNotFoundError(error)) return <Error error={error} statusCode={404} />;
  if (isPermissionsError(error)) return <Error error={error} statusCode={401} />;
  return <Error error={error} statusCode={500} />;
};

export default handleError;

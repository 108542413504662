import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ErrorPage } from '@loanpal/lumos__layout';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { LegacyOriginAuthorization } from '@goodleap/authentication-mfe';
import { PubNubProvider } from '@/hooks/usePubSub';

import type { BugsnagPluginReactResult } from '@bugsnag/plugin-react';

import App from './app';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY as string,
  plugins: [new BugsnagPluginReact()],
  // In order to properly debug locally, we've disabled console log breadcrumbs. More info: https://docs.bugsnag.com/platforms/javascript/configuration-options/#consolebreadcrumbsenabled-browser-only
  releaseStage: process.env.STAGE === 'devint01' ? 'development' : process.env.STAGE,
  enabledReleaseStages: ['development', 'prod', 'sandbox', 'stage'],
  onError: event => {
    if (event.app.releaseStage === 'development') {
      console.debug(event.errors[0]);
      return false; // discard the error
    }
    return true;
  },
});

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
const ErrorBoundary = plugin.createErrorBoundary(React);

const Root = () => (
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <StrictMode>
      <BrowserRouter>
        <LegacyOriginAuthorization>
          <PubNubProvider>
            {process.env.REACT_APP_STANDALONE && <div id="root" />}
            <App />
          </PubNubProvider>
        </LegacyOriginAuthorization>
      </BrowserRouter>
    </StrictMode>
  </ErrorBoundary>
);

export default Root;

import { DateTime } from 'luxon';

export const moneyStringToNumber = (money: string): number =>
  Number.parseFloat(money.replace(/[^0-9.]/g, ''));

export const percentStringToNumber = (money: string): number => Number.parseFloat(money.replace(/,/g, '.'));

export const formatDate = (
  date: string,
  format?: { [key: string]: string | boolean },
  zone?: string
): string =>
  format ? DateTime.fromISO(date, { zone }).toLocaleString(format) : DateTime.fromISO(date).toLocaleString();

export const parseDateFromString = (value: string) => {
  const tmp = value.split('-');
  if (tmp.length < 3) return undefined;
  if (tmp[0].length < 4) return undefined;

  const year = Number.parseInt(tmp[0], 10);
  const month = Number.parseInt(tmp[1], 10);
  const day = Number.parseInt(tmp[2], 10);

  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return undefined;
  }

  const dateObject = { year, month, day };
  return DateTime.fromObject(dateObject).toISO();
};

export const formatDateTime = (date: string): string =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL as any);

export const formatISODate = (date: string): string | null => DateTime.fromISO(date).toISODate();

export const dateDiffInMonths = (date1: string, date2: string) =>
  Math.round(DateTime.fromISO(date2).diff(DateTime.fromISO(date1), 'months').months * 100) / 100;

export const dateDiffInDays = (date1: string, date2: string) =>
  Math.floor(DateTime.fromISO(date2).diff(DateTime.fromISO(date1), 'days').days);

export const dateDiffInDaysDateObj = (date1: Date, date2: Date) =>
  Math.floor((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));

export const dateDiffInHours = (date1: string, date2: string) =>
  Math.round(DateTime.fromISO(date2).diff(DateTime.fromISO(date1), 'hours').hours);

export const dateDiffInMinutes = (date1: string, date2: string) =>
  Math.round(DateTime.fromISO(date2).diff(DateTime.fromISO(date1), 'minutes').minutes);

export const dateDiffInSeconds = (date1: string, date2: string) =>
  Math.round(DateTime.fromISO(date2).diff(DateTime.fromISO(date1), 'seconds').seconds);

export const visuallyHidden = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
};

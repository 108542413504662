import { lazy } from 'react';

const ContractReviewContainer = lazy(() => import('./components/index'));
const ContractReviewHistoryDetail = lazy(
  () => import('./pages/contract-review-history/contract-review-history-detail')
);

const routes = [
  {
    path: '/loans/:loanId/cases/:caseId/contract-review',
    element: <ContractReviewContainer />,
  },
  {
    path: '/contract-review-history/:caseId',
    element: <ContractReviewHistoryDetail />,
  },
];

export default routes;

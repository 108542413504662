import React from 'react';

import { formatDate } from '../../utils';
import {
  ContractReviewHistoryPropTypes as ContractReviewHistoryPropertyTypes,
  dateFormat,
  getIconBasedOnDisposition,
} from './utils';

type PropertyTypes = {
  item: ContractReviewHistoryPropertyTypes;
};

const CollapsibleTitle: React.FC<PropertyTypes> = ({ item }) => {
  const { disposition, completionDate, label, actionCompletedBy } = item;
  return (
    <div
      data-testid="collapsible-title"
      style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
    >
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {getIconBasedOnDisposition(disposition)}
        <p sx={{ ml: 2 }}>{label}</p>
      </div>
      <div sx={{ alignItems: 'center', display: 'flex', mr: 2 }}>
        <p sx={{ fontWeight: 600, mr: 2 }}>
          {actionCompletedBy?.userName || actionCompletedBy?.userGivenName},
        </p>
        {formatDate(completionDate, dateFormat)}
      </div>
    </div>
  );
};

export default CollapsibleTitle;

export const downloadFile = async (url: string, fileName?: string, withoutBlob?: boolean): Promise<void> => {
  if (typeof window === 'undefined') {
    throw new Error('Window is not defined');
  }
  let objUrl = url;
  if (!withoutBlob) {
    const response = await fetch(url, {
      method: 'GET',
    });
    const blob = await response.blob();
    objUrl = window.URL.createObjectURL(blob);
  }
  const a = document.createElement('a');
  a.href = objUrl;
  a.download = '';

  /* if a filename is provided the file will be downloaded to the user machine
    with that name if not, default name will be kept */
  if (fileName) {
    a.download = fileName;
  }

  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

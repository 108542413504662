import React, { memo } from 'react';
import { ContractReviewDetails as TContractReviewDetails } from 'src/types/contract-review';
import { SubmitContractReviewItemDisposition as Disposition } from 'src/types/global-types';
import { OfferForContractReviewById as Offer } from 'src/types/loans';
import { getItemDetails } from 'src/utils/contract-review-history';
import { ContractReviewHistory } from '@loanpal/lumos__layout';

const decisionsMap = {
  [Disposition.PASS]: 'approve',
  [Disposition.FAIL]: 'deny',
  [Disposition.NOT_PRESENT]: 'missing',
};

const ContractReviewDetails: React.FC<{ contractReview: TContractReviewDetails; offer: Offer }> = ({
  contractReview,
  offer,
}) => {
  const initialDecisions: Record<string, string> = {};

  contractReview?.form?.items?.forEach((item: { disposition: any; code: any }) => {
    const { disposition: itemDisposition, code } = item;
    if (itemDisposition) {
      initialDecisions[code] = decisionsMap[itemDisposition as keyof typeof decisionsMap];
    }
  });

  const {
    form: { items: contractReviewFormItems },
    reviewHistory: { comment = '', recipients: requestRecipients },
  } = contractReview || {};

  const tableData = contractReviewFormItems
    ?.filter(
      ({ severity, variance, code, value: origValue }: any) =>
        severity || (variance && variance !== origValue) || code === 'addonsPresent'
    )
    .map((item: any) => getItemDetails(item, initialDecisions, offer));

  const displayedComment = comment || '';
  const recipients = requestRecipients || [];
  const showFailedDetailsSection = !!(recipients.length > 0 || displayedComment.length > 0);
  const displayedRecipients = recipients.join(', ');

  return (
    <ContractReviewHistory
      isContractReviewHistorySummaryView
      comment={displayedComment}
      contractReviewData={tableData}
      recipients={displayedRecipients}
      showFailedDetailsSection={showFailedDetailsSection}
    />
  );
};

export default memo(ContractReviewDetails);

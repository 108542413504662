import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { NotifyProvider } from '@loanpal/lumos__core';
import ThemeProvider, { GoodLeap } from '@loanpal/lumos__theme';

// eslint-disable-next-line import/no-unresolved
import { GraphProvider } from '@goodleap/authentication-mfe';

import routes from './routes';
import { useFeatureFlags } from './hooks';

const App = () => {
  useFeatureFlags();
  const appRoutes = useRoutes(routes);

  return (
    // CR web uses react 18 while authentication-mfe uses react 17. the typings are different because of the react version
    // @ts-ignore
    <GraphProvider stage={process.env.STAGE as string} uri={process.env.FEDERATED_GRAPHQL_URL as string}>
      <ThemeProvider theme={GoodLeap as any}>
        {
          // @ts-ignore
          <NotifyProvider>
            <Suspense fallback={null}>{appRoutes}</Suspense>
          </NotifyProvider>
        }
      </ThemeProvider>
    </GraphProvider>
  );
};

export default App;

import Bugsnag from '@bugsnag/js';
import { ErrorPage, NotFoundPage, UnauthorizedPage } from '@loanpal/lumos__layout';
import React from 'react';

const Error: React.FC<{ error?: any; statusCode: number }> = ({ statusCode, error }) => {
  React.useEffect(() => {
    if (statusCode === 500) {
      Bugsnag.notify(error);
    }
  }, [statusCode]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!statusCode) return <ErrorPage />;

  const styling = {
    '> div': {
      '@media screen and (max-width: 80em)': {
        pb: 4,
      },
      pb: 6,
    },
    bg: 'neutral.l4',
    height: '100%',
    width: '100%',
  };

  const renderNode = () => {
    switch (statusCode) {
      case 404: {
        return <NotFoundPage />;
      }

      case 401: {
        return <UnauthorizedPage />;
      }

      default: {
        return <ErrorPage />;
      }
    }
  };

  return <div sx={styling}>{renderNode()}</div>;
};

export default Error;

/* eslint-disable no-case-declarations */
import { DateTime } from 'luxon';
import { Credit, CreditResponse, OfferForContractReviewById as Offer } from '@/types/loans';
import {
  ContractReviewInputValue,
  SubmitContractReviewInput,
  SubmitContractReviewItemDisposition as Disposition,
  SubmitContractReviewItemReason as HICFailReasons,
  SubmitContractReviewItemSeverity as Severity,
} from '@/types/global-types';
import { formatDate, parseDateFromString } from '@/utils/index';
import { downloadFile } from '@/utils/download';
import { ContractReview } from '@/types/contract-review';
import { StaticDocuments } from '@/types/documents';

import {
  Addon,
  DecisionsType,
  FailedDispositions,
  FinanceParty,
  MandatoryValueMeta,
  MissingSignature,
} from './types';

export const OTHER_ADDON_PREFIX = 'SolarAddon#';
export const OTHER_ADDON_CODE = `${OTHER_ADDON_PREFIX}Other`;

const createDownloadableDocsMapper = (
  documentTypes: (StaticDocuments | null)[],
  type: string,
  numberOfDocumentsToBeDownloaded: number
) =>
  documentTypes.slice(0, numberOfDocumentsToBeDownloaded).reduce(
    (accumulatorDoc, doc) => {
      if (!doc?.downloadUrl) return accumulatorDoc;
      return {
        [type]: [...accumulatorDoc[type], doc.downloadUrl],
      };
    },
    { [type]: [] as string[] }
  );

export const docsItems = (
  docs: (StaticDocuments | null)[],
  borrowerCredit: Credit | null | undefined,
  coborrowerCredit: Credit | null | undefined,
  contractReviewId: string | undefined,
  supportMultipleActiveDocuments?: boolean
) => {
  const allHICDocs = docs.filter(t => t?.type === 'HIC');
  const allHICAddendumDocs = docs.filter(t => t?.type === 'HIC_ADDENDUM');
  const allSupplementalDocs = docs.filter(t => t?.type === 'HICSupplement');
  const allOtherDocs = docs.filter(t => {
    const caseIdDocMetadata = (t?.metadata || []).find(data => data?.key === 'caseId');

    // get the "other" type docs and that are for the specific contract review case
    return t?.type === 'OTHER' && caseIdDocMetadata?.value === contractReviewId;
  });

  const allCreditReportDocs = ((creditBorrower, creditCoborrower) => {
    const result: CreditResponse[] = [];

    const addReportDocs = (borrower: Credit) => {
      const { experian, equifax, transunion } = borrower;
      if (experian) {
        result.push(experian);
      }
      if (equifax) {
        result.push(equifax);
      }
      if (transunion) {
        result.push(transunion);
      }
    };

    if (creditBorrower) {
      addReportDocs(creditBorrower);
    }
    if (creditCoborrower) {
      addReportDocs(creditCoborrower);
    }

    return result;
  })(borrowerCredit, coborrowerCredit);

  const sortingMethod = (a: StaticDocuments | null, b: StaticDocuments | null) =>
    DateTime.fromISO(b?.uploadedAt || '').valueOf() - DateTime.fromISO(a?.uploadedAt || '').valueOf();

  allHICDocs.sort(sortingMethod);
  allHICAddendumDocs.sort(sortingMethod);
  allSupplementalDocs.sort(sortingMethod);
  allOtherDocs.sort(sortingMethod);

  if (allCreditReportDocs.length > 1) allCreditReportDocs.sort((a, b) => (b?.score || 0) - (a?.score || 0));

  const creditReportDoc = allCreditReportDocs[0];

  const numberOfDocumentsToBeDownloaded = supportMultipleActiveDocuments ? 6 : 1;
  const downloadableHicDocs: Record<string, string[]> = createDownloadableDocsMapper(
    allHICDocs,
    'HIC',
    numberOfDocumentsToBeDownloaded
  );

  const downloadableHicAddendumDocs: Record<string, string[]> = createDownloadableDocsMapper(
    allHICAddendumDocs,
    'Addendum',
    numberOfDocumentsToBeDownloaded
  );

  const downloadableHicSupplementalDocs: Record<string, string[]> = createDownloadableDocsMapper(
    allSupplementalDocs,
    'Supplemental',
    numberOfDocumentsToBeDownloaded
  );

  const downloadableOtherDocs: Record<string, string[]> = createDownloadableDocsMapper(
    allOtherDocs,
    'Other',
    numberOfDocumentsToBeDownloaded
  );

  const creditReportDownloadableDoc: Record<string, string[]> = {
    'Credit Report': creditReportDoc?.alfaXReportUrl ? [creditReportDoc.alfaXReportUrl] : [],
  };

  return {
    ...creditReportDownloadableDoc,
    ...downloadableHicDocs,
    ...downloadableHicAddendumDocs,
    ...downloadableHicSupplementalDocs,
    ...downloadableOtherDocs,
  };
};

export const hicFailItems = [
  { label: 'No, Missing HIC', value: HICFailReasons.MISSING },
  { label: 'No, Wrong HIC', value: HICFailReasons.INCORRECT },
  { label: 'No, Illegible HIC', value: HICFailReasons.ILLEGIBLE },
  { label: 'No, Incomplete HIC', value: HICFailReasons.INCOMPLETE },
];

export const financePartyFailItems = [
  { name: 'Aqua', value: FinanceParty.Aqua },
  { name: 'Dividend', value: FinanceParty.Dividend },
  { name: 'Foundation', value: FinanceParty.Foundation },
  { name: 'Greensky', value: FinanceParty.Greensky },
  { name: 'MCU', value: FinanceParty.MCU },
  { name: 'Mosiac', value: FinanceParty.Mosiac },
  { name: 'Service Finance', value: FinanceParty.ServiceFinance },
  { name: 'Sunlight', value: FinanceParty.Sunlight },
  { name: 'Wells Fargo', value: FinanceParty.WellsFargo },
  { name: 'Other', value: FinanceParty.Other },
];

export const dispositionsWithSeverityItems = [
  { label: 'Minor', value: Severity.MINOR },
  { label: 'Major (New HIC is needed)', value: Severity.MAJOR_HIC },
  { label: 'Major (New loan docs are needed)', value: Severity.MAJOR_DOCS },
  {
    label: 'Major (New HIC and new loan docs are needed)',
    value: Severity.MAJOR_HIC_AND_DOCS,
  },
];

export const missingSignatureItems = [
  { label: 'Borrower signature not present', value: MissingSignature.Borrower },
  { label: 'Installer signature not present', value: MissingSignature.Installer },
];

export const effectiveDateValue = (decision: string, value?: string) => {
  if (decision === 'deny') return 'Not on HIC';
  if (!value) return '';
  const parsedDate = parseDateFromString(value);
  if (!parsedDate) return '';
  return formatDate(parsedDate);
};

export const yesOrNoValue = (decision?: string) => {
  if (decision === 'approve') return 'Yes';
  if (decision === 'deny') return 'No';
  return '';
};

export const passOrFailValue = (decision?: string) => {
  if (decision === 'approve') return 'Pass';
  if (decision === 'deny') return 'Fail';
  return '';
};

export const yesNoOrNotPresentValue = (decision?: string) => {
  if (decision === 'approve') return 'Yes';
  if (decision === 'deny') return 'No';
  if (decision === 'missing') return 'Not on HIC';
  return '';
};

export const getDispositionEnum = (disposition: 'approve' | 'missing' | 'deny') => {
  if (disposition === 'missing') return Disposition.NOT_PRESENT;
  if (disposition === 'approve') return Disposition.PASS;
  return Disposition.FAIL;
};

export const financePartyValue = (decision: string, value?: string, other?: string) => {
  if (decision === 'approve') return 'True';
  const financeParty = financePartyFailItems.find(item => item.value === value);
  if (financeParty?.value === FinanceParty.Other) return `Other - ${other}`;
  return financeParty?.name || '';
};

export const getFinPartyValue = (variance?: string, other?: string) =>
  variance === 'Other' ? `Other - ${other}` : variance;

export const formatOfferLabel = ({ baseRate, rate, term }: Offer, selectedProduct?: any) => {
  const promotionalPeriod = selectedProduct?.labels.promotionalPeriod;

  if (promotionalPeriod) {
    return (
      <>
        <span sx={{ display: 'block' }}>
          {promotionalPeriod}/{term} yr, {rate}% APR (Autopay)
        </span>
        <span>
          {promotionalPeriod}/{term} yr, {baseRate}% APR
        </span>
      </>
    );
  }

  return (
    <div>
      <div>
        {term} yr, {rate}% APR (Autopay)
      </div>
      <div>
        {term} yr, {baseRate}% APR
      </div>
    </div>
  );
};

export const hicValue = (decision: string, value?: string): string => {
  if (decision === 'approve') return 'Yes, HIC is valid';
  if (decision === 'deny') return hicFailItems.find(item => item.value === value)?.label || '';
  return '';
};

export const formatFailedHicValue = (value?: string) => {
  if (!value) return '';

  const valueMap: { [key: string]: string } = {
    [HICFailReasons.MISSING]: 'Missing',
    [HICFailReasons.INCORRECT]: 'Wrong',
    [HICFailReasons.ILLEGIBLE]: 'Illegible',
    [HICFailReasons.INCOMPLETE]: 'Incomplete',
  };
  return valueMap[value];
};

export const formatMissingSignatureValue = (value?: string) => {
  if (!value) return '';
  if (value.includes(MissingSignature.Borrower) && value.includes(MissingSignature.Installer))
    return `${MissingSignature.Borrower} and ${MissingSignature.Installer} have not signed the change`;
  return `${value} has not signed the change`;
};

export const noOfYearsValue = (value?: string): string => {
  if (!value) return '';
  return `${value} year${Number.parseFloat(value) === 1 ? '' : 's'}`;
};

export const formatAddonType = (addon: string | undefined): string | { name: string; sort: string } => {
  if (!addon) return { name: '', sort: '' };
  const [, name, sort] = addon.split('#');
  return { name, sort };
};

export const addonsValue = (addons: Addon[] | undefined): string => {
  if (!addons || !addons.length) return '';
  const formatedAddonType = formatAddonType(addons[0]?.type);
  const name = typeof formatedAddonType === 'string' ? formatedAddonType : formatedAddonType.name;
  const first = name;

  let secondName = '';
  if (addons.length > 1) {
    const secondFormatedAddonType = formatAddonType(addons[1]?.type);
    secondName =
      typeof secondFormatedAddonType === 'string' ? secondFormatedAddonType : secondFormatedAddonType.name;
    secondName = `, ${secondName}`;
  }
  const third = addons.length > 2 ? ` +${addons.length - 2} more` : '';
  return `${first}${secondName}${third}`;
};

export const mapAddonsToValuesType = (addons: Addon[]): ContractReviewInputValue[] =>
  addons.reduce((acc: ContractReviewInputValue[], { type, value }: Addon) => {
    if (type && value) return acc.concat([{ code: type, value: value.toString() }]);
    return acc;
  }, [] as ContractReviewInputValue[]);

export const mapValuesToAddonsType = (values: ContractReviewInputValue[]): Addon[] =>
  values.reduce((acc: Addon[], { code, value }: ContractReviewInputValue) => {
    if (code && value) return acc.concat([{ type: code, value: parseFloat(value) }]);
    return acc;
  }, [] as Addon[]);

export const contractReviewDefaultState: FailedDispositions = {
  adderAllowance: { variance: '' },
  addonsPresent: { values: undefined },
  borrowerName: { variance: '', severity: undefined },
  city: { variance: '', severity: undefined },
  coborrowerName: { variance: '', severity: undefined },
  effectiveDate: { variance: '' },
  financeParty: { variance: undefined, other: '' },
  hic: { reason: undefined },
  installerName: { variance: '' },
  loanProduct: { variance: '' },
  loanTermAndRate: { variance: '' },
  pricePerWatt: { variance: '' },
  projectCost: { variance: '' },
  roofPenWarrantyYears: { variance: '' },
  signature: { variance: '' },
  systemSize: { variance: '' },
  state: { variance: '', severity: undefined },
  street: { variance: '', severity: undefined },
  workmanshipWarrantyYears: { variance: '' },
  zip: { variance: '', severity: undefined },
  batterySize: { variance: '' },
  extendedWarranties: { variance: '' },
};

export const getInitialValues = ({
  contractReviewInput,
  contractReviewData,
}: {
  contractReviewInput: SubmitContractReviewInput | null;
  contractReviewData?: ContractReview;
}) => {
  const defaultDecisions: DecisionsType = {};

  const defaultState = { ...contractReviewDefaultState };

  const defaultValues = { ...defaultState };

  const decisionsMap: DecisionsType = {
    [Disposition.PASS]: 'approve',
    [Disposition.FAIL]: 'deny',
    [Disposition.NOT_PRESENT]: 'missing',
  };

  const financePartyValues = (variance: string) => {
    const [, otherParty] = variance.split('Other - ');
    if (otherParty) return { variance: FinanceParty.Other, other: otherParty };
    return { variance: FinanceParty[variance as FinanceParty], other: '' };
  };

  contractReviewInput?.items?.forEach(({ code, disposition, reason, values, variance, severity }) => {
    if (disposition) {
      defaultDecisions[code] = decisionsMap[disposition];
    }

    switch (code) {
      case 'hic':
        if (reason) defaultState[code] = { reason };
        break;

      case 'adderAllowance':
      case 'pricePerWatt':
      case 'projectCost':
      case 'loanProduct':
      case 'loanTermAndRate':
      case 'installerName':
      case 'effectiveDate':
      case 'roofPenWarrantyYears':
      case 'workmanshipWarrantyYears':
      case 'signature':
      case 'systemSize':
      case 'extendedWarranties':
        if (variance) defaultState[code] = { variance };
        break;

      case 'borrowerName':
      case 'coborrowerName':
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        if (variance && severity) defaultState[code] = { variance, severity };
        break;

      case 'financeParty':
        if (variance) defaultState[code] = financePartyValues(variance);
        break;

      case 'addonsPresent':
        if (values) defaultState[code] = { values: mapValuesToAddonsType(values) };
        break;

      default:
        break;
    }
  });

  contractReviewData?.form?.items?.forEach(({ code, reason, value, values, severity }) => {
    switch (code) {
      case 'hic':
        if (reason) defaultValues[code] = { reason };
        break;

      case 'adderAllowance':
      case 'pricePerWatt':
      case 'projectCost':
      case 'loanProduct':
      case 'loanTermAndRate':
      case 'installerName':
      case 'effectiveDate':
      case 'roofPenWarrantyYears':
      case 'workmanshipWarrantyYears':
      case 'signature':
      case 'systemSize':
      case 'batterySize':
      case 'extendedWarranties':
        defaultValues[code] = { variance: value || defaultValues[code].variance };
        break;

      case 'borrowerName':
      case 'coborrowerName':
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        // eslint-disable-next-line no-case-declarations
        const { variance: defaultVariance, severity: defaultSeverity } = defaultValues[code];
        defaultValues[code] = {
          variance: value || defaultVariance,
          severity: severity || defaultSeverity,
        };
        break;

      case 'financeParty':
        if (value) defaultValues[code] = financePartyValues(value);
        break;

      case 'addonsPresent':
        if (values) defaultValues[code] = { values: mapValuesToAddonsType(values) };
        break;

      default:
        break;
    }
  });

  return {
    initialDecisions: defaultDecisions,
    initialState: defaultState,
    prepopulatedValues: defaultValues,
  };
};

// extracts only the fields specified in the keys array
export const destruct = (obj: any, ...keys: string[]) => keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});

export const itemsMetaData: { [code: string]: any } = {
  loanProduct: {
    failCopy: 'Loan product does not match the Home Improvement Contract (HIC)',
  },
  installerName: {
    failCopy: 'Installer name does not match the Home Improvement Contract (HIC)',
  },
  borrowerName: {
    failCopy: 'Borrower name does not match the Home Improvement Contract (HIC)',
  },
  coborrowerName: {
    failCopy: 'Co-borrower name does not match the Home Improvement Contract (HIC)',
  },
  street: { failCopy: 'Street does not match the Home Improvement Contract (HIC)' },
  city: { failCopy: 'City does not match the Home Improvement Contract (HIC)' },
  state: {
    failCopy: 'State / Territory does not match the Home Improvement Contract (HIC)',
  },
  zip: { failCopy: 'Zip / Postal code does not match the Home Improvement Contract (HIC)' },
  effectiveDate: {
    failCopy: 'Effective date is not present on the Home Improvement Contract (HIC)',
  },
  borrowerSignature: {
    failCopy: 'Borrower signature is not present on the Home Improvement Contract (HIC)',
  },
  installerSignature: {
    failCopy: 'Installer signature is not present on the Home Improvement Contract (HIC)',
  },
  workmanshipWarrantyYears: {
    failCopy: 'Workmanship warranty must be a minimum of 10 years',
  },
  roofPenWarrantyYears: {
    failCopy: 'Roof penetration warranty must be a minimum of 5 years',
  },
  rightToCancel: {
    failCopy: 'Customer right to cancel is not present on the Home Improvement Contract (HIC)',
  },
  modified: {
    failCopy: 'Home Improvement Contract (HIC) appears to have been modified after the customer signed',
  },
  systemSize: {
    failCopy: 'System size (kW) does not match the Home Improvement Contract (HIC)',
  },
  solarDisclosure: {
    failCopy:
      'State specific solar disclosure one-pager is not present on the Home Improvement Contract (HIC)',
  },
  batterySize: {
    failCopy: 'Battery size (kWh) does not match the Home Improvement Contract (HIC)',
  },
  extendedWarranties: {
    failCopy: 'Extended warranties do not match the Home Improvement Contract (HIC)',
  },
  changeOrderBorrowerSignature: {
    failCopy: 'Borrower has not signed the change',
  },
};

export const mandatoryValuesWhenApproved: { [key: string]: MandatoryValueMeta } = {
  effectiveDate: { action: 'provide effective date', name: 'Effective Date' },
  roofPenWarrantyYears: {
    action: 'enter the number of years',
    name: 'the roof penetration warranty term present (# of years)',
  },
  workmanshipWarrantyYears: {
    action: 'enter the number of years',
    name: 'the workmanship warranty term present (# of years)',
  },
  extendedWarranties: {
    action: 'provide the extended warranties',
    name: 'the Extended warranties period',
  },
};

export const toastMessages = {
  missingBorrowers:
    'You must select approve or deny for either the Borrower name or Co-borrower name before completing the review.',
  skippedHIC: 'You must complete Valid HIC item before completing the review.',
  skippedReasons: 'You must provide a reason for all failed items before completing the review.',
};

const downloadSpecificDoc = (url: string) =>
  downloadFile(url, url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?')));

const delayPromise = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });

export const downloadDocs = (downloadableDocsUrls: string[]) =>
  downloadableDocsUrls.reduce((accumulatorPromise: any, url) => {
    if (!url) return accumulatorPromise;
    return accumulatorPromise.then(() => Promise.all([delayPromise(1500), downloadSpecificDoc(url)]));
  }, Promise.resolve());

export const downloadWithCustomName = (urls: { url?: string; name?: string }[]) =>
  urls.reduce((accumulatorPromise: any, { url, name }) => {
    if (!url) return accumulatorPromise;
    return accumulatorPromise.then(() => Promise.all([delayPromise(1500), downloadFile(url, name)]));
  }, Promise.resolve());

export const clearUnwantedAutosavedVariance = ({
  code,
  disposition,
  originalValue,
  variance,
}: {
  code: string;
  disposition: Disposition;
  originalValue: string | null | undefined;
  variance: string | undefined;
}) => {
  // a value sent as variance for projectCost will trigger an event that will send a fyi email
  // to the partner even if there is no discrepancy
  const prePopulatedPanelCodes = ['projectCost'];
  if (!prePopulatedPanelCodes.includes(code)) return variance;
  let returnedVariance = variance;
  if ([Disposition.PASS].includes(disposition) && originalValue === variance) {
    returnedVariance = '';
  }
  return returnedVariance;
};

export enum CategoryCode {
  Roofing1 = 'ROOF_1',
  HomeImprovement1 = 'HI_1',
  Solar = 'Solar',
  Battery = 'Battery',
}

const openDocument = (url: string) =>
  new Promise(resolve => {
    window.open(url);
    resolve(true);
  });

export const openDocsToDownload = (urls: string[]) =>
  urls.reduce(
    (accumulatorPromise: any, url) =>
      accumulatorPromise.then(() => Promise.all([delayPromise(500), openDocument(url)])),
    Promise.resolve()
  );

const omit = <T extends object, K extends keyof T>(object: T, ...keys: K[]) =>
  Object.fromEntries(Object.entries(object).filter(([key]) => !keys.includes(key as K))) as Omit<T, K>;

export const getDownloadableUrls = (documents: Record<string, string[]>) => {
  const downloadableDocumentTypes = omit(documents, 'Credit Report');
  return Object.values(downloadableDocumentTypes).reduce((accumulator, downloadableUrls) => {
    if (!downloadableUrls.length) return accumulator;
    return [...accumulator, ...downloadableUrls];
  }, []);
};

export const downloadDocsByUrls = (downloadableDocsUrls: string[]) =>
  downloadableDocsUrls.reduce(
    (accumulatorPromise: any, url) =>
      accumulatorPromise.then(() => Promise.all([delayPromise(1500), downloadSpecificDoc(url)])),
    Promise.resolve()
  );

import React, { memo, useEffect, useMemo, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { Collapsible } from '@loanpal/lumos__core';
import ThemeProvider, { GoodLeap } from '@loanpal/lumos__theme';
import Skeleton from 'react-loading-skeleton';
import { Grid } from 'theme-ui';
// eslint-disable-next-line import/no-unresolved
import { useElevatedTokenQuery } from '@goodleap/origin-utils-mfe';
import {
  GetLoanInfoForContractReviewById,
  GetLoanInfoForContractReviewByIdVariables,
  OfferForContractReviewById as Offer,
} from '@/types/loans';
import { GetContractReviewSummary, HistoryItems as ContractReviewItems } from 'src/types/contract-review';
import { GET_CONTRACT_REVIEW_SUMMARY } from '@/operations/contract-review';
import { getContractReviewInformationMessage } from '@/utils/contract-review-history';
import { GET_LOAN_INFO_FOR_CONTRACT_REVIEW_BY_ID } from '@/operations/loans';

import handleError from '../../utils/graph-error';
import ContractReviewSummary from '../../views/contract-review-summary/contract-review-summary';
import { useEnableContractRevisionHistory } from './utils';

type PropertyTypes = {
  contractReviewHistoryData: GetContractReviewSummary | undefined;
  contractReviewHistoryFetchError: ApolloError | undefined;
  enableContractReviewHistory: boolean;
  isContractReviewHistoryLoading: boolean;
  offer: Offer;
};

const ContractReviewHistory: React.FC<PropertyTypes> = memo(
  ({
    contractReviewHistoryData,
    contractReviewHistoryFetchError,
    enableContractReviewHistory,
    isContractReviewHistoryLoading,
    offer,
  }) => {
    const [contractReviewHistoryItems, setContractReviewHistoryItems] = useState<ContractReviewItems[]>([]);
    const [displayedInformationMessage, setDisplayedInformationMessage] = useState<string>();

    useEffect(() => {
      const contractReviewItems = enableContractReviewHistory
        ? contractReviewHistoryData?.contractReviewHistory?.items.filter(item => item.disposition) || []
        : [];

      const displayedMessage = enableContractReviewHistory
        ? getContractReviewInformationMessage(
            contractReviewHistoryData?.contractReviewHistory?.message as string,
            contractReviewItems as ContractReviewItems[]
          )
        : undefined;

      setContractReviewHistoryItems(contractReviewItems);
      setDisplayedInformationMessage(displayedMessage);
    }, [contractReviewHistoryData?.contractReviewHistory, enableContractReviewHistory]);

    const renderedCRHistoryItems = useMemo(
      () =>
        contractReviewHistoryItems.map(history => (
          <ContractReviewSummary key={history.caseId} collapsibleItem={history} offer={offer ?? {}} />
        )),
      [contractReviewHistoryItems, offer]
    );

    if (contractReviewHistoryFetchError) return handleError(contractReviewHistoryFetchError);

    return (
      // @ts-ignore because of: Property 'children' does not exist on type 'IntrinsicAttributes & PropTypes'
      <Collapsible initialOpen title={<p sx={{ fontWeight: 600 }}>Contract Review History</p>}>
        {isContractReviewHistoryLoading ? (
          <Skeleton containerTestId="loading-skeleton" height={48} width="100%" />
        ) : (
          <Grid as="dl" gap={2} sx={{ paddingY: 3 }}>
            {displayedInformationMessage || renderedCRHistoryItems}
          </Grid>
        )}
      </Collapsible>
    );
  }
);

const ContractReviewHistoryWrapper: React.FC<{ loanId: string }> = ({ loanId }) => {
  const { data: loanData } = useQuery<
    GetLoanInfoForContractReviewById,
    GetLoanInfoForContractReviewByIdVariables
  >(GET_LOAN_INFO_FOR_CONTRACT_REVIEW_BY_ID, {
    variables: { loanId },
    fetchPolicy: 'cache-first',
  });

  const { data, loading, error } = useElevatedTokenQuery<GetContractReviewSummary>(
    GET_CONTRACT_REVIEW_SUMMARY,
    {
      variables: { loanId },
    }
  );

  const enableContractReviewHistory = useEnableContractRevisionHistory(loanData?.loan.version || '');
  const offer = loanData?.loan.offer || ({} as Offer);

  return (
    <ThemeProvider theme={GoodLeap as any}>
      <ContractReviewHistory
        contractReviewHistoryData={data}
        contractReviewHistoryFetchError={error}
        enableContractReviewHistory={enableContractReviewHistory}
        isContractReviewHistoryLoading={loading}
        offer={offer}
      />
    </ThemeProvider>
  );
};

export default ContractReviewHistoryWrapper;

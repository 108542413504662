import { useEffect } from 'react';
import { featureFlags } from '@/utils/feature-flags';

const useFeatureFlags = () => {
  useEffect(() => {
    featureFlags.initialize();
  }, []);
};

export default useFeatureFlags;

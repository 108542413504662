export enum UserClaim {
  CanDoEverything = 'can_do_everything',
  CanReadCases = 'can_read_cases',
  CanReadOrgCases = 'can_read_org_cases',
  CanReadReferenceIdCases = 'can_read_refid_cases',
}

export enum ClaimPrefix {
  Dir = 'dir',
  Lss = 'lss',
  Ogn = 'ogn',
  Wrk = 'wrk',
}

import PubNub from 'pubnub';
import { useEffect, FC, useMemo, ReactNode } from 'react';
import { usePubNub, PubNubProvider as PubNubReactProvider } from 'pubnub-react';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { useAuth } from '@goodleap/authentication-mfe';

type User = any;

interface Options {
  pubChannels?: string[];
  subChannels?: string[];
  pubState?: Record<string, any>;
}

const PUBNUB_PUBLISH_KEY = process.env.REACT_APP_PUBNUB_PUB_KEY as string;
const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUB_KEY as string;

const PUBNUB_HEARTBEAT_MS = 15;
const PUBNUB_PRESENCE_TIMEOUT_MS = 20;

export const usePubSub = ({ pubChannels, subChannels, pubState }: Options) => {
  const pubnub = usePubNub();

  useEffect(() => {
    pubnub.setState({ state: pubState, channels: pubChannels });
    return () => {
      pubnub.setState({ state: {}, channels: pubChannels });
    };
  }, [pubChannels, pubState, pubnub]);

  useEffect(() => {
    pubnub.subscribe({ channels: subChannels, withPresence: true });
    return () => {
      pubnub.unsubscribe({ channels: subChannels });
    };
  }, [subChannels, pubnub]);
};

const usePubNubService = (user: User | undefined) => {
  const { id, firstName, lastName, role } = user || {};

  const client = useMemo(() => {
    const idFullName = `${id}_${firstName?.replace(/\s+/g, '')}_${lastName?.replace(/\s+/g, '')}_${
      role?.name
    }`;

    return new PubNub({
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      heartbeatInterval: PUBNUB_HEARTBEAT_MS,
      presenceTimeout: PUBNUB_PRESENCE_TIMEOUT_MS, // presence timeout minimum = 20
      uuid: idFullName,
    });
  }, [firstName, id, lastName, role?.name]);

  return client;
};

export const PubNubProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const client = usePubNubService(user);

  useEffect(() => client.unsubscribeAll, [client]);

  return <PubNubReactProvider client={client}>{children}</PubNubReactProvider>;
};

import { ReactNode } from 'react';
import {
  SubmitContractReviewAction,
  SubmitContractReviewItemInput,
  SubmitContractReviewItemReason as HICFailReasons,
  SubmitContractReviewItemSeverity as Severity,
  SubmitContractReviewItemDisposition as Disposition,
  SubmitContractReviewItemDisposition,
} from '@/types/global-types';
import { Validation } from '@/types/contract-review';

export type ItemType = {
  name: string;
  title: string;
  value: string | ReactNode;
  sort: number;
  allowMissing?: boolean;
  allowPass?: boolean;
  allowFail?: boolean;
  isNotValid?: boolean;
  panel?: ReactNode;
};

export type MetaData = {
  sort: number;
  title: string;
  label: string;
  allowMissing?: boolean;
  allowFail?: boolean;
  categories?: string[];
};

export type ContractReviewItem = {
  code: string;
  isMissing?: boolean;
  isPassing?: boolean;
  metadata: MetaData;
  originalValue?: string | null;
  other?: string;
  reason?: HICFailReasons;
  severity?: Severity;
  validation: Validation | null;
  value?: string | FinanceParty;
  values?: Addon[];
  variance?: string;
};

export type ReviewedItem = ContractReviewItem & { disposition: Disposition };

export type RequiredOn = {
  reason?: Disposition[];
  severity?: Disposition[];
  values?: Disposition[];
  variance?: Disposition[];
};

export enum FinanceParty {
  Aqua = 'Aqua',
  Dividend = 'Dividend',
  Foundation = 'Foundation',
  Greensky = 'Greensky',
  MCU = 'MCU',
  Mosiac = 'Mosiac',
  ServiceFinance = 'ServiceFinance',
  Sunlight = 'Sunlight',
  WellsFargo = 'WellsFargo',
  Other = 'Other',
}

export enum MissingSignature {
  Borrower = 'Borrower',
  Installer = 'Installer',
}

export type Addon = {
  value?: number;
  type?: string;
};

type AddonsPresent = { values?: Addon[] };

type VarianceAndSeverityType = { variance: string; severity?: Severity };

type ContractReviewFinancePartyType = { variance?: FinanceParty; other?: string };

type HicStateType = { reason?: HICFailReasons };

export type FailedDispositions = {
  adderAllowance: { variance: string };
  addonsPresent: AddonsPresent;
  borrowerName: VarianceAndSeverityType;
  city: VarianceAndSeverityType;
  coborrowerName: VarianceAndSeverityType;
  effectiveDate: { variance: string };
  financeParty: ContractReviewFinancePartyType;
  hic: HicStateType;
  installerName: { variance: string };
  loanProduct: { variance: string };
  loanTermAndRate: { variance: string };
  pricePerWatt: { variance: string };
  projectCost: { variance: string };
  roofPenWarrantyYears: { variance: string };
  signature: { variance: string };
  systemSize: { variance: string };
  state: VarianceAndSeverityType;
  street: VarianceAndSeverityType;
  workmanshipWarrantyYears: { variance: string };
  zip: VarianceAndSeverityType;
  batterySize: VarianceAndSeverityType;
  extendedWarranties: { variance: string };
};

export type Offer = {
  id: string;
  rate: number;
  baseRate: number;
  term: number;
};

export type DataDispatchType = {
  type: 'SET_DATA';
  data: FailedDispositions;
};

export type ItemDispatchType = {
  item: string;
  other?: string;
  reason?: HICFailReasons;
  severity?: string;
  values?: Addon[];
  variance?: string | FinanceParty;
  type?: 'SET_DATA';
  data?: FailedDispositions;
};

export type DispatchType = DataDispatchType | ItemDispatchType;

export type DecisionsType = {
  [name: string]: undefined | 'approve' | 'missing' | 'deny';
};

export type ContractReviewInput = {
  action: SubmitContractReviewAction;
  items: SubmitContractReviewItemInput[];
};

export type MandatoryValueMeta = {
  action: string;
  name: string;
};

export type FailedItems = Record<string, { isNotValid: boolean }>;

export const decisionDispositionMapped: Record<
  'approve' | 'missing' | 'deny',
  SubmitContractReviewItemDisposition
> = {
  approve: SubmitContractReviewItemDisposition.PASS,
  deny: SubmitContractReviewItemDisposition.FAIL,
  missing: SubmitContractReviewItemDisposition.NOT_PRESENT,
};

import { ClaimPrefix, UserClaim } from 'src/enums/user-enums';
import semver from 'semver';
import { V2_LOAN_VERSION } from '@/utils/constants';
// eslint-disable-next-line import/no-unresolved
import { useElevatedClaimCheck } from '@goodleap/origin-utils-mfe';

export const useEnableContractRevisionHistory = (version: string) => {
  const checkClaim = useElevatedClaimCheck();
  const { Wrk: prefix } = ClaimPrefix;
  const canReadContractReviewCases =
    checkClaim({ claim: UserClaim.CanReadCases, prefix }) ||
    checkClaim({ claim: UserClaim.CanDoEverything, prefix }) ||
    checkClaim({ claim: UserClaim.CanReadReferenceIdCases, prefix }) ||
    checkClaim({ claim: UserClaim.CanReadOrgCases, prefix });
  const isV2Loan = semver.satisfies((version || '').toString(), V2_LOAN_VERSION);
  return isV2Loan && canReadContractReviewCases;
};

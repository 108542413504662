import { gql } from '@apollo/client';

export const CATEGORY = gql`
  fragment Category on Category {
    name
    code
  }
`;

export const OFFER = gql`
  fragment Offer on Offer {
    id
    orgId
    term
    rate
    baseRate
    product {
      labels {
        promotionalPeriod
      }
    }
  }
`;

export const OFFER_CR_BY_ID = gql`
  fragment OfferForContractReviewById on Offer {
    id
    term
    rate
    baseRate
    product {
      labels {
        promotionalPeriod
      }
    }
  }
`;

export const CREDIT_RESPONSE = gql`
  fragment CreditResponse on creditResponse {
    score
    chosen
    alfaXReportUrl
  }
`;

export const CREDIT = gql`
  ${CREDIT_RESPONSE}
  fragment Credit on Credit {
    equifax {
      ...CreditResponse
    }
    experian {
      ...CreditResponse
    }
    transunion {
      ...CreditResponse
    }
  }
`;

export const BORROWER = gql`
  ${CREDIT}
  fragment Borrower on Borrower {
    firstName
    lastName
    credit {
      ...Credit
    }
  }
`;

export const LOAN = gql`
  ${OFFER}
  ${BORROWER}
  ${CATEGORY}
  fragment Loan on Loan {
    id
    autopayIntent
    staticDocuments {
      downloadUrl
      documentName
      type
      uploadedAt
      metadata {
        key
        value
      }
    }
    loanDocuments {
      id
      downloadUrl
      completedAt
    }
    offer {
      ...Offer
    }
    borrower {
      ...Borrower
    }
    coBorrower {
      ...Borrower
    }
    creditBureau
    creditScore
    failedContractReviewRecipients
    category {
      ...Category
    }
  }
`;

export const LOAN_CR_BY_ID = gql`
  ${OFFER_CR_BY_ID}
  fragment LoanForContractReviewById on Loan {
    id
    version
    category {
      name
    }
    offer {
      ...OfferForContractReviewById
    }
  }
`;

export const GET_LOAN_BY_ID = gql`
  ${LOAN}
  query GET_LOAN_BY_ID($loanId: ID!) {
    loan(id: $loanId) {
      ...Loan
    }
  }
`;
export const GET_LOAN_INFO_FOR_CONTRACT_REVIEW_BY_ID = gql`
  ${LOAN_CR_BY_ID}
  query GET_LOAN_INFO_FOR_CONTRACT_REVIEW_BY_ID($loanId: ID!) {
    loan(id: $loanId) {
      ...LoanForContractReviewById
    }
  }
`;

import {
  LDClient,
  LDFlagSet,
  LDUser,
  LDFlagChangeset,
  initialize as LDInitialize,
} from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';
import camelCase from 'lodash/camelCase';

type Listener = (val: LDFlagSet) => void;
type UnsubscribeMethod = () => void;

class FeatureFlagService {
  private static instance: FeatureFlagService;

  private flags: LDFlagSet = {};

  private listeners: Listener[] = [];

  private client?: LDClient;

  private initialized = false;

  static getInstance(): FeatureFlagService {
    if (!this.instance) {
      this.instance = new this();
    }

    return this.instance;
  }

  private constructor() {} // eslint-disable-line no-useless-constructor, no-empty-function

  get(): LDFlagSet {
    return this.flags;
  }

  set(val: LDFlagSet) {
    if (this.flags !== val) {
      this.flags = val;
      this.listeners.forEach(l => l(val));
    }
  }

  subscribe(listener: Listener): UnsubscribeMethod {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter(l => l !== listener);
    };
  }

  initialize() {
    if (this.initialized) return;

    this.client = LDInitialize(
      process.env.LAUNCHDARKLY as string,
      {
        anonymous: true,
        key: 'anonymousUser',
      },
      { autoAliasingOptOut: true }
    );

    this.client.on('ready', () => {
      const flags = { ...this.flags };
      const rawFlags = this.client?.allFlags() ?? {};
      Object.keys(rawFlags).forEach(f => {
        // Exclude system keys
        if (f.indexOf('$') !== 0) {
          flags[camelCase(f)] = rawFlags[f];
        }
      });
      this.set(flags);
    });

    this.client.on('change', (changes: LDFlagChangeset) => {
      const flags = { ...this.flags };
      Object.keys(changes).forEach(key => {
        flags[camelCase(key)] = changes[key].current;
      });
      this.set(flags);
    });

    this.initialized = true;
  }

  identify(user: LDUser) {
    if (!this.initialized) {
      this.initialize();
    }
    return this.client?.identify(user);
  }
}

export const featureFlags = FeatureFlagService.getInstance();

export const useFlags = () => {
  const [flags, setFlags] = useState(featureFlags.get());

  useEffect(() => featureFlags.subscribe(setFlags), []);

  return flags;
};

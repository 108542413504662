import { Disposition } from '@/types/contract-review';
import { Icon } from '@loanpal/lumos__core';

const DispositionLabelMap = {
  Approved: 'Approved',
  Canceled: 'Canceled',
  Escalated: 'Escalated',
  EscalatedInternal: 'EscalatedInternal',
  EscalatedPartner: 'EscalatedPartner',
  Failed: 'Failed',
  Rejected: 'Rejected',
  WaitingOnNewContract: 'WaitingOnNewContract',
};

export const getIconBasedOnDisposition = (disposition: string) => {
  if (
    [
      DispositionLabelMap.Escalated,
      DispositionLabelMap.EscalatedInternal,
      DispositionLabelMap.EscalatedPartner,
    ].includes(disposition)
  )
    return <Icon color="white" variant="color-warning" />;
  if (disposition === DispositionLabelMap.Approved) return <Icon color="white" variant="color-success" />;
  if (
    [
      DispositionLabelMap.WaitingOnNewContract,
      DispositionLabelMap.Rejected,
      DispositionLabelMap.Failed,
      DispositionLabelMap.Canceled,
    ].includes(disposition)
  )
    return <Icon color="#AD0000" variant="color-times" />;
  return null;
};

export const dateFormat = {
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export type IndividualContractReviewHistory = {
  comment?: string;
  recipients?: string[];
  when?: string;
  who?: string;
};

export type ContractReviewLastModifiedBy = {
  userGivenName?: string | null;
  userId?: string | null;
  userName?: string | null;
};

export type ContractReviewHistoryPropTypes = {
  actionCompletedBy?: ContractReviewLastModifiedBy;
  caseId: string;
  completionDate: string;
  disposition: Disposition;
  label: string;
};

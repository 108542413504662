export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AccountPaginationInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum AchStatus {
  ERROR = 'Error',
  INVALID = 'Invalid',
  LEGACY = 'Legacy',
  OPTOUT = 'OptOut',
  REJECTED = 'Rejected',
  REQUESTED = 'Requested',
  UNVERIFIED = 'Unverified',
  VALIDATED = 'Validated',
  VERIFIED = 'Verified',
}

export enum AchValidationStatus {
  ERROR = 'Error',
  UNVERIFIED = 'Unverified',
  VERIFIED = 'Verified',
}

export type AddAttachmentToCaseInput = {
  /** Unique idenitifier of file you are trying to attach. */
  attachmentId: Scalars['ID']['input'];
  /** Unique idenitifier of the case you are trying to attach the file to. */
  caseId: Scalars['ID']['input'];
};

export enum BlockingMilestoneTypes {
  APPROVAL = 'APPROVAL',
  NONE = 'NONE',
  NTP = 'NTP',
}

export enum BorrowerNextActionTypes {
  COMPLETED = 'COMPLETED',
  GET_LOAN_DOCS = 'GET_LOAN_DOCS',
  NONE = 'NONE',
  RESEND_LOAN_DOCS = 'RESEND_LOAN_DOCS',
  SKIP_AND_GET_LOAN_DOCS = 'SKIP_AND_GET_LOAN_DOCS',
  SKIP_AND_RESEND_LOAN_DOCS = 'SKIP_AND_RESEND_LOAN_DOCS',
}

export enum CaseActors {
  BORROWER = 'BORROWER',
  DISTRIBUTOR = 'DISTRIBUTOR',
  GOODLEAP = 'GOODLEAP',
  INVESTOR = 'INVESTOR',
  LOANPAL = 'LOANPAL',
  NONE = 'NONE',
  PARTNER = 'PARTNER',
  SALES = 'SALES',
  SYSTEM = 'SYSTEM',
}

export enum CaseAttachmentStatus {
  ACCEPTED = 'ACCEPTED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
}

export enum CaseSortType {
  ACTOR = 'Actor',
  BORROWER = 'Borrower',
  CASEAGE = 'CaseAge',
  CASENAME = 'CaseName',
  CURRENTALIAS = 'CurrentAlias',
  DATECREATED = 'DateCreated',
  DATEMODIFIED = 'DateModified',
  LOANID = 'LoanId',
  NONE = 'None',
  PRIORITY = 'Priority',
  STATUSAGE = 'StatusAge',
}

export type CaseSorting = {
  order?: InputMaybe<SortOrder>;
  sortField: CaseSortType;
};

export enum CaseStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export enum CaseType {
  CONTRACT_REVIEW = 'CONTRACT_REVIEW',
  ID_VALIDATION = 'ID_VALIDATION',
  INCOME_VERIFICATION = 'INCOME_VERIFICATION',
  MORTGAGE_PAYMENT_VERIFICATION = 'MORTGAGE_PAYMENT_VERIFICATION',
  OTHER = 'OTHER',
}

export type CloseCaseInput = {
  /** Unique identifier of the case */
  caseId: Scalars['ID']['input'];
  /** disposition of the case */
  disposition: Scalars['String']['input'];
  /** Indicates if the case was completed */
  isComplete: Scalars['Boolean']['input'];
  /** Stringified JSON body to supply on a steps data packet */
  jsonData?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteCaseStepInput = {
  /** Action found on the cases step */
  action: Scalars['String']['input'];
  /** ID of the case */
  caseId: Scalars['ID']['input'];
  /** Stringified JSON body to supply on a steps data packet */
  jsonData?: InputMaybe<Scalars['String']['input']>;
  /** ID of the step */
  stepId: Scalars['ID']['input'];
};

export type ConnectionPaginationArgs = {
  /** Take the first or last N nodes after this cursor. */
  after?: InputMaybe<Scalars['String']['input']>;
  /** Take the first or last N nodes before this cursor. */
  before?: InputMaybe<Scalars['String']['input']>;
  /** Takes the first N nodes before or after a cursor. */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Takes the last N nodes before or after a cursor. */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ContractReviewDataInput = {
  items: Array<Scalars['String']['input']>;
  loanId: Scalars['ID']['input'];
};

export type ContractReviewHistoryDetailInput = {
  caseId: Scalars['ID']['input'];
};

export type ContractReviewHistoryInput = {
  loanId: Scalars['ID']['input'];
};

export type ContractReviewInputValue = {
  code: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum CreditBureau {
  EQUIFAX = 'equifax',
  EXPERIAN = 'experian',
  TRANSUNION = 'transunion',
}

/** Input options when adding currency to an Object */
export type CurrencyInput = {
  type: CurrencyType;
  value: Scalars['Float']['input'];
};

/** Indicates what type of currency */
export enum CurrencyType {
  USD = 'USD',
}

export type DispositionCaseAttachmentInput = {
  /** The attachment ID supplied on the case */
  attachmentId: Scalars['ID']['input'];
  /** The cases unique identifier */
  caseId: Scalars['ID']['input'];
  /** Disposition for the attachment */
  disposition: CaseAttachmentStatus;
};

export type GetCaseSingleFileUploadUrlInput = {
  /**
   * Add the requested upload to a specific set of other attachments. This is useful when uploaded multiple
   * single pages that may be part of the same document.
   */
  attachmentGroupId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique idenitifier of the case you are trying to add a file to. */
  caseId: Scalars['ID']['input'];
  /**
   * Represents the type of document to clear a given case. This is constrained by the case type, so ensure the
   * document to clear type passed was provided on the given case you intend to upload a document for.
   */
  documentToClearType: Scalars['String']['input'];
  /**
   * Custom name you'd like to have for the file. Defaults to the attachmentId otherwise.
   * Example: Awesome Income Page 1 of 4
   */
  fileLabel?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the loan. */
  loanId: Scalars['ID']['input'];
  /** The file type you would like to upload. */
  mimeType: Scalars['String']['input'];
};

export enum HomeOccupationEnum {
  INVESTMENT = 'INVESTMENT',
  OTHER = 'OTHER',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum HomeOwnershipEnum {
  NOT_OWNED = 'NOT_OWNED',
  OWNED_FREE_AND_CLEAR = 'OWNED_FREE_AND_CLEAR',
  OWNED_WITH_MORTGAGE = 'OWNED_WITH_MORTGAGE',
}

export enum IncomeType {
  MODELED = 'modeled',
  STATED = 'stated',
  VERIFIED = 'verified',
}

export type IndexedPaginationInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum LoanActionType {
  APPROVE = 'APPROVE',
  CANCEL = 'CANCEL',
  CERTIFY_PROJECT = 'CERTIFY_PROJECT',
  CHANGE_ORDER = 'CHANGE_ORDER',
  CONTRACT_REVIEW = 'CONTRACT_REVIEW',
  CREATE_REFUND_INVOICE = 'CREATE_REFUND_INVOICE',
  DECLINE = 'DECLINE',
  EDIT_BORROWER = 'EDIT_BORROWER',
  EDIT_COBORROWER = 'EDIT_COBORROWER',
  EDIT_PAYEE = 'EDIT_PAYEE',
  HOW_TO_RESOLVE = 'HOW_TO_RESOLVE',
  INSTALL_COMPLETE = 'INSTALL_COMPLETE',
  /** @deprecated Use 'EDIT PAYEE' instead */
  MANAGE_PAYEE = 'MANAGE_PAYEE',
  /** @deprecated Use 'EDIT PAYEE' instead */
  PAYEE_SELECTION = 'PAYEE_SELECTION',
  PERMISSION_TO_OPERATE = 'PERMISSION_TO_OPERATE',
  REGENERATE_CPC = 'REGENERATE_CPC',
  REGENERATE_DOCS = 'REGENERATE_DOCS',
  RERUN_UNDERWRITING = 'RERUN_UNDERWRITING',
  RESEND_CPC = 'RESEND_CPC',
  RESEND_DOCS = 'RESEND_DOCS',
  RESOLVE_CASES = 'RESOLVE_CASES',
  RESOLVE_IN_UW_CONTRACT_REVIEW = 'RESOLVE_IN_UW_CONTRACT_REVIEW',
  RESOLVE_IN_UW_LOAN_DETAILS = 'RESOLVE_IN_UW_LOAN_DETAILS',
  REVIEW_AGAIN = 'REVIEW_AGAIN',
  SEND_LOAN_DOCS = 'SEND_LOAN_DOCS',
  SIGN_DOCS = 'SIGN_DOCS',
  UPDATE_PROPERTY = 'UPDATE_PROPERTY',
  UPDATE_REFERENCE_NUMBER = 'UPDATE_REFERENCE_NUMBER',
  UPDATE_SOURCE = 'UPDATE_SOURCE',
  UPLOAD_HIC = 'UPLOAD_HIC',
  UPLOAD_STIPS = 'UPLOAD_STIPS',
  VERIFY_LOAN_DETAILS = 'VERIFY_LOAN_DETAILS',
  VERIFY_PROJECT_INFO = 'VERIFY_PROJECT_INFO',
}

export type NoteInput = {
  body: Scalars['String']['input'];
  /** Unique identifier for the loan */
  loanId: Scalars['ID']['input'];
  /** Only viewable by Loanpal internal users */
  private: Scalars['Boolean']['input'];
  /** Case ID */
  reference?: InputMaybe<Scalars['String']['input']>;
};

export enum ProgressStatus {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  WARNING = 'WARNING',
}

export type QueryBorrowerActionableCasesInput = {
  /** Filter cases by its referenceId. This is the loan ID. */
  referenceId: Scalars['ID']['input'];
};

export type QueryCaseInput = {
  /** Identifier for the case */
  id: Scalars['ID']['input'];
};

export type QueryCasesInput = {
  /** Filter cases based on the actor its 'waiting on'. */
  actor?: InputMaybe<CaseActors>;
  /** Filter a case by on its case status */
  caseStatus?: InputMaybe<CaseStatus>;
  /** Filter cases by its workflows */
  caseWorkflowIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter the cases by its directory organization ID */
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter the cases by its directory organizations ID */
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter arguments for pagination */
  pagination?: InputMaybe<ConnectionPaginationArgs>;
  /** Filter cases by its referenceId. This can be the loan ID. */
  referenceId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<CaseSorting>;
  /** Filter cases by its status */
  status?: InputMaybe<Scalars['String']['input']>;
};

export type ReopenCaseInput = {
  /** Unique identifier of the case */
  caseId: Scalars['ID']['input'];
};

export type ReviewAgainContractReviewInput = {
  loanId: Scalars['ID']['input'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum StateCodes {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MISSING = 'MISSING',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export enum SubmitContractReviewAction {
  ESCALATE_EXTERNAL = 'ESCALATE_EXTERNAL',
  ESCALATE_INTERNAL = 'ESCALATE_INTERNAL',
  FAIL = 'FAIL',
  PASS = 'PASS',
}

export type SubmitContractReviewInput = {
  /** Action to perform on the review */
  action: SubmitContractReviewAction;
  /** The optional comment */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Contract review case unique identifier */
  id: Scalars['ID']['input'];
  /** The line items required for the review. Must match the form. */
  items?: InputMaybe<Array<SubmitContractReviewItemInput>>;
  /** Any email recipients for the failure */
  recipients?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum SubmitContractReviewItemDisposition {
  FAIL = 'FAIL',
  NOT_PRESENT = 'NOT_PRESENT',
  PASS = 'PASS',
}

export type SubmitContractReviewItemInput = {
  code: Scalars['String']['input'];
  disposition: SubmitContractReviewItemDisposition;
  reason?: InputMaybe<SubmitContractReviewItemReason>;
  severity?: InputMaybe<SubmitContractReviewItemSeverity>;
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<ContractReviewInputValue>>;
  variance?: InputMaybe<Scalars['String']['input']>;
};

export enum SubmitContractReviewItemReason {
  ILLEGIBLE = 'ILLEGIBLE',
  INCOMPLETE = 'INCOMPLETE',
  INCORRECT = 'INCORRECT',
  MISSING = 'MISSING',
}

export enum SubmitContractReviewItemSeverity {
  MAJOR_DOCS = 'MAJOR_DOCS',
  MAJOR_HIC = 'MAJOR_HIC',
  MAJOR_HIC_AND_DOCS = 'MAJOR_HIC_AND_DOCS',
  MINOR = 'MINOR',
}

export type TransitionToCaseNodeInput = {
  /** Case's unique identifier */
  caseId: Scalars['ID']['input'];
  /** Target node key for the transition */
  nodeKey: Scalars['String']['input'];
};

import { HistoryItems as ContractReviewItems } from 'src/types/contract-review';
import { OfferForContractReviewById as Offer } from 'src/types/loans';
import { Button, Icon } from '@loanpal/lumos__core';

import { SubmitContractReviewItemDisposition as Disposition } from '../types/global-types';
import {
  addonsValue,
  dispositionsWithSeverityItems,
  downloadDocsByUrls,
  effectiveDateValue,
  financePartyValue,
  formatOfferLabel,
  hicValue,
  mapValuesToAddonsType,
  noOfYearsValue,
  passOrFailValue,
  yesNoOrNotPresentValue,
  yesOrNoValue,
} from '../components/utils';

type DispositionType = {
  allowFail: boolean;
  allowMissing: boolean;
};

export const getContractReviewInformationMessage = (
  message: string,
  contractReviewItems: ContractReviewItems[]
) => {
  if (message !== 'Contract review history retrieved') return message;
  if (contractReviewItems.length === 0) return 'There are currently no completed contract reviews';
  return '';
};

export const getItemDetails = (
  {
    code,
    disposition,
    values,
    value: originalValue,
    metadata,
    validation,
    variance,
    severity: originalSeverity,
    reason,
  }: any,
  initialDecisions: Record<string, string>,
  offer: Offer
) => {
  const name = code;
  const value = originalValue || '';
  const severity =
    originalSeverity &&
    dispositionsWithSeverityItems.find(element => element.value === originalSeverity)?.label;
  const allowedDispositions = validation?.allowedDispositions.reduce(
    (accumulator: DispositionType, disp: Disposition) => {
      if (disp === Disposition.FAIL) {
        accumulator.allowFail = true;
      }

      if (disp === Disposition.NOT_PRESENT) {
        accumulator.allowMissing = true;
      }

      return accumulator;
    },
    { allowFail: false, allowMissing: false }
  );
  switch (code) {
    case 'hic': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: hicValue(initialDecisions?.hic, reason),
        variance,
      };
    }

    case 'projectCost':
    case 'installerName':
    case 'loanProduct':
    case 'systemSize':
    case 'extendedWarranties':
    case 'batterySize':
    case 'borrowerName':
    case 'coborrowerName':
    case 'street':
    case 'city':
    case 'state':
    case 'zip': {
      return {
        disposition,
        name,
        value,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        variance,
      };
    }

    case 'loanTermAndRate': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: formatOfferLabel(offer, offer.product) || '',
        variance,
      };
    }

    case 'effectiveDate': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: effectiveDateValue(initialDecisions?.effectiveDate, variance),
        variance: effectiveDateValue(initialDecisions?.effectiveDate, variance),
      };
    }

    case 'modified': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: passOrFailValue(initialDecisions?.modified),
        variance,
      };
    }

    case 'solarDisclosure':
    case 'borrowerSignature':
    case 'installerSignature':
    case 'rightToCancel': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: yesOrNoValue(initialDecisions && initialDecisions[code]),
        variance,
      };
    }

    case 'installerLicense':
    case 'signature':
    case 'changeOrderBorrowerSignature': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: yesNoOrNotPresentValue(initialDecisions && initialDecisions[code]),
        variance,
      };
    }

    case 'financeParty': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: financePartyValue(initialDecisions?.financeParty, variance),
        variance,
      };
    }

    case 'workmanshipWarrantyYears':
    case 'roofPenWarrantyYears': {
      return {
        disposition,
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: noOfYearsValue(variance),
        variance: noOfYearsValue(variance),
      };
    }

    case 'adderAllowance':
      return {
        name,
        disposition,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        value: `${variance}%`,
        variance: `${variance}%`,
        severity,
      };

    case 'pricePerWatt':
      return {
        name,
        disposition,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        value: `$${variance}`,
        variance: `$${variance}`,
        severity,
      };

    case 'addonsPresent': {
      return {
        name,
        ...allowedDispositions,
        ...JSON.parse(metadata),
        severity,
        value: values && addonsValue(mapValuesToAddonsType(values)),
        values: values && mapValuesToAddonsType(values),
        variance,
      };
    }

    default: {
      return null;
    }
  }
};

export const createDownloadButton = (type: string, urls: string[]) => (
  <Button
    sx={{
      color: 'primary.base',
      fontSize: 2,
      textDecoration: 'underline',
      textTransform: 'none' as const,
      '&:hover': {
        color: 'primary.d2',
        bg: 'transparent',
        textDecoration: 'underline',
      },
      p: 0,
      minHeight: '0px',
      border: '0px',
    }}
    variant="ghost"
    onClick={() => downloadDocsByUrls(urls)}
  >
    <Icon variant="download" sx={{ mr: '5px', py: 'auto' }} size="sm" />
    {type}
  </Button>
);

import { gql } from '@apollo/client';

export const REQUIRED_ON = gql`
  fragment RequiredOn on SubmitContractReviewItemRequiredFields {
    reason
    severity
    values
    variance
  }
`;

export const VALIDATION = gql`
  ${REQUIRED_ON}
  fragment Validation on ContractReviewValidation {
    allowedDispositions
    requiredOn {
      ...RequiredOn
    }
    allowableVariation {
      category
      variation
    }
  }
`;

export const ITEMS = gql`
  ${VALIDATION}
  fragment Items on ContractReviewItemOutput {
    code
    disposition
    value
    values {
      code
      value
    }
    variance
    reason
    severity
    metadata
    validation {
      ...Validation
    }
  }
`;

export const HISTORY_ITEMS = gql`
  fragment HistoryItems on ContractReviewHistory {
    caseId
    disposition
    label
    actionCompletedBy {
      userId
      userName
      userGivenName
    }
    completionDate
  }
`;

export const CONTRACT_REVIEW = gql`
  ${ITEMS}
  fragment ContractReview on ContractReviewOutput {
    caseId
    form {
      items {
        ...Items
      }
    }
  }
`;

export const FETCH_CONTRACT_REVIEW = gql`
  ${CONTRACT_REVIEW}
  query FETCH_CONTRACT_REVIEW($caseId: ID!) {
    contractReview(id: $caseId) {
      ...ContractReview
    }
  }
`;

export const SUBMIT_CONTRACT_REVIEW = gql`
  mutation SUBMIT_CONTRACT_REVIEW($input: SubmitContractReviewInput!) {
    submitContractReview(input: $input) {
      id
    }
  }
`;

export const GET_CONTRACT_REVIEW_HISTORY_DETAIL = gql`
  query GET_CONTRACT_REVIEW_HISTORY_DETAIL($caseId: ID!) {
    contractReviewHistoryDetail(caseId: $caseId) {
      success
      message
      data {
        caseId
        disposition
        loanId
        attachments {
          id
          caseAttachmentId
          mimeType
          type
          name
          attachmentGroupId
          status
          downloadUrl
          uploadedBy
          uploadedAt
        }
        contractReview {
          caseId
          history {
            action
            who
            when
            recipients
            items {
              code
              disposition
              value
              variance
              reason
              severity
              values {
                value
                code
              }
              validation {
                allowableVariation {
                  category
                  variation
                }
                requiredOn {
                  variance
                  value
                  values
                  severity
                  reason
                }
                allowedDispositions
              }
              metadata
            }
            comment
          }
          form {
            items {
              code
              disposition
              value
              variance
              reason
              severity
              values {
                value
                code
              }
              validation {
                allowableVariation {
                  category
                  variation
                }
                requiredOn {
                  variance
                  value
                  values
                  severity
                  reason
                }
                allowedDispositions
              }
              metadata
            }
          }
          reviewHistory {
            when
            who
            comment
            recipients
          }
        }
      }
    }
  }
`;

export const GET_CONTRACT_REVIEW_SUMMARY = gql`
  ${HISTORY_ITEMS}
  query GET_CONTRACT_REVIEW_SUMMARY($loanId: ID!) {
    contractReviewHistory(loanId: $loanId) {
      requiresContractReview
      success
      message
      items {
        ...HistoryItems
      }
    }
  }
`;

import React from 'react';
import { ButtonLink, Collapsible, Icon } from '@loanpal/lumos__core';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// eslint-disable-next-line import/no-unresolved
import { useElevatedTokenLazyQuery } from '@goodleap/origin-utils-mfe';
import { GET_CONTRACT_REVIEW_HISTORY_DETAIL } from 'src/operations/contract-review';
import {
  GetContractReviewHistoryDetail,
  GetContractReviewHistoryDetailVariables as HistoryVariables,
} from 'src/types/contract-review';
import { OfferForContractReviewById as Offer } from 'src/types/loans';
import handleError from 'src/utils/graph-error';

import CollapsibleTitle from './collapsible-title';
import ContractReviewDetails from './contract-review-details';
import { ContractReviewHistoryPropTypes as ContractReviewHistoryPropertyTypes } from './utils';

export type ContractReviewPropTypes = {
  collapsibleItem: ContractReviewHistoryPropertyTypes;
  offer: Offer;
};

const SummarySkeleton: React.FC = () => (
  <SkeletonTheme baseColor="#d7d7d7" highlightColor="#f5f5f5">
    <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {Array.from({ length: 4 }, (_, index) => (
        <div key={index} sx={{ width: '20%' }}>
          <Skeleton height={24} />
        </div>
      ))}
    </div>
  </SkeletonTheme>
);

const ContractReviewSummary: React.FC<ContractReviewPropTypes> = ({ collapsibleItem, offer }) => {
  const { caseId, disposition } = collapsibleItem;

  const [fetchContractReviewHistoryDetail, { data, loading, error }] = useElevatedTokenLazyQuery<
    GetContractReviewHistoryDetail,
    HistoryVariables
  >(GET_CONTRACT_REVIEW_HISTORY_DETAIL);

  const openDetailsInNewTab = () => window.open(`/contract-review-history/${caseId}`, '_blank');

  const handleToggle = () => {
    if (!data && !loading) {
      fetchContractReviewHistoryDetail({
        variables: { caseId },
      });
    }
  };

  if (error) return handleError(error);

  const isLoading = loading || !data;
  const hideNewTabButton = ['EscalatedPartner', 'EscalatedInternal'].includes(disposition);

  return (
    <div
      sx={{
        alignItems: 'baseline',
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
      }}
    >
      {!hideNewTabButton && (
        <div>
          <ButtonLink type="button" onClick={openDetailsInNewTab}>
            <Icon size="md" title="Open details in new tab" variant="open-new-tab" />
          </ButtonLink>
        </div>
      )}
      <div sx={{ width: '100%' }}>
        {/* @ts-ignore because of: Property 'children' does not exist on type 'IntrinsicAttributes & PropTypes' */}
        <Collapsible title={<CollapsibleTitle item={collapsibleItem} />} onToggle={handleToggle}>
          <div
            sx={{
              '& > div': {
                textAlign: 'start',
              },
              border: 'none',
              ml: 'auto',
              width: '100%',
            }}
          >
            {isLoading ? (
              <SummarySkeleton />
            ) : (
              <ContractReviewDetails
                contractReview={data.contractReviewHistoryDetail.data.contractReview}
                offer={offer}
              />
            )}
          </div>
          {!hideNewTabButton && (
            <div>
              <ButtonLink
                sx={{ alignItems: 'end', display: 'flex' }}
                type="button"
                onClick={openDetailsInNewTab}
              >
                Open details in new tab
                <Icon
                  color="white"
                  size="sm"
                  sx={{ ml: 1 }}
                  title="Open details in new tab"
                  variant="open-new-tab"
                />
              </ButtonLink>
            </div>
          )}
        </Collapsible>
      </div>
    </div>
  );
};

export default ContractReviewSummary;

import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import Root from './root.component';

// CSS is generated even if this is a component based MFE
import 'react-loading-skeleton/dist/skeleton.css';
// Anything exported from this file is importable by other in-browser modules.
export * from './index';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(error) {
    return (
      <div
        role="alert"
        sx={{
          color: 'red',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>{`Uh oh... There's a problem. Try refreshing the app.`}</p>
        <pre>{error.message}</pre>
      </div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
